<div [ngClass]="{ 'bg-container': !embeddedMode }">
  <app-component-notification></app-component-notification>

  <div class="bg-header" *ngIf="!embeddedMode && this.settings?.toolbar?.isShowTitle !== false">
    <h3>
      <i class="material-icons mr-1">{{ info?.icon }}</i>
      {{ info?.name }}
    </h3>
  </div>

  <div [ngClass]="{ 'bg-content': !embeddedMode }">
    <div class="items">
      <dx-data-grid
        #dynamicGrid
        id="{{ dataGridId }}"
        [noDataText]="'Keine Daten verfügbar oder vorhanden'"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [dataSource]="dataSource"
        [hoverStateEnabled]="true"
        [remoteOperations]="true"
        [selectedRowKeys]="[]"
        [showBorders]="false"
        [showColumnLines]="true"
        [repaintChangesOnly]="true"
        [rowAlternationEnabled]="settings?.toolbar.rowAlternationEnabled"
        [twoWayBindingEnabled]="false"
        (onCellHoverChanged)="onShowToolTipColumn($event)"
        (onExporting)="onExporting($event)"
        (onEditorPreparing)="onEditorPreparing($event)"
        (onOptionChanged)="onOptionChanged($event)"
        (onSelectionChanged)="onSelectDocument($event)">
        <!-- Grid settings -->
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-selection [mode]="selectionMode" showCheckBoxesMode="always"></dxo-selection>
        <dxo-paging [pageSize]="pageSize || 25"></dxo-paging>
        <dxo-pager
          [showPageSizeSelector]="!embeddedMode"
          [visible]="pagerVisible"
          [showInfo]="true"
          [allowedPageSizes]="allowedPageSizes"></dxo-pager>
        <dxo-column-chooser [enabled]="!embeddedMode" mode="select" height="300"></dxo-column-chooser>
        <dxo-search-panel [visible]="true" text="{{ this.listState.grid?.searchText || '' }}"></dxo-search-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-grouping [contextMenuEnabled]="settings?.toolbar.isShowGrouping" [autoExpandAll]="false"></dxo-grouping>
        <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-editing
          mode="row"
          [allowUpdating]="false"
          [allowAdding]="false"
          [allowDeleting]="allowDeleting"
          useIcons="true">
          <dxo-texts deleteRow="Löschen" confirmDeleteMessage=""></dxo-texts>
        </dxo-editing>

        <!-- Toolbar -->
        <dxo-toolbar [visible]="!embeddedMode">
          <!-- group panel -->
          <dxi-item *ngIf="settings?.toolbar.isShowGrouping && !embeddedMode" name="groupPanel" location="before">
          </dxi-item>

          <dxi-item
            *ngFor="let button of this.buttons"
            location="{{ button.location || 'after' }}"
            locateInMenu="auto"
            widget="{{ button.widget || 'dxButton' }}"
            [options]="button.options">
          </dxi-item>

          <!-- date range -->
          <dxi-item
            *ngIf="this.settings?.toolbar.isShowPersonalMode"
            location="after"
            locateInMenu="auto"
            widget="dxDropDownButton"
            [options]="{
              useSelectMode: true,
              selectedItemKey: this.listState.toolbar?.personalMode || 1,
              width: 150,
              displayExpr: 'name',
              keyExpr: 'value',
              items: [
                { value: 1, name: 'Alle', icon: 'group' },
                { value: 2, name: 'Nur Meine', icon: 'user' }
              ],
              onItemClick: onPersonalModeSwitch
            }">
          </dxi-item>

          <!-- date range -->
          <dxi-item
            *ngIf="this.settings?.toolbar.isShowDateFilter"
            location="after"
            locateInMenu="auto"
            widget="dxDropDownButton"
            [options]="{
              useSelectMode: true,
              selectedItemKey: this.listState.toolbar.selectedDateRange || 'currentMonth',
              width: 250,
              displayExpr: 'label',
              keyExpr: 'value',
              items: dateRangeDataSource,
              onItemClick: onDateRange
            }">
          </dxi-item>

          <!-- preview switch -->
          <dxi-item
            *ngIf="this.settings?.toolbar.isShowPreviewSwitch"
            location="after"
            locateInMenu="auto"
            widget="dxSwitch"
            [options]="{
              height: '35px',
              width: '80px',
              switchedOffText: 'Vorschau aus',
              switchedOnText: 'Vorschau an',
              value: this.listState.toolbar?.isPreviewEnabled || false,
              onValueChanged: onPreviewSwitch
            }">
          </dxi-item>

          <!-- refresh button -->
          <dxi-item
            location="after"
            locateInMenu="auto"
            showText="inMenu"
            widget="dxButton"
            [options]="{
              icon: 'refresh',
              text: 'Liste neu laden',
              hint: 'Liste neu laden',
              height: '35px',
              stylingMode: 'outlined',
              onClick: onRefresh
            }">
          </dxi-item>

          <!-- export button -->
          <dxi-item *ngIf="settings?.toolbar.isShowExportExcel && !embeddedMode" name="exportButton"></dxi-item>

          <!-- column chooser button -->
          <dxi-item name="columnChooserButton"></dxi-item>

          <!-- search button -->
          <dxi-item
            *ngIf="settings?.toolbar.isShowSearch && !embeddedMode"
            name="searchPanel"
            locateInMenu="auto"
            [options]="{ width: 250 }">
          </dxi-item>

          <!-- reset filter button -->
          <dxi-item
            location="after"
            locateInMenu="auto"
            showText="inMenu"
            widget="dxButton"
            [options]="{
              text: 'Liste zurücksetzen',
              hint: 'Liste zurücksetzen',
              icon: 'clearformat',
              height: '35px',
              stylingMode: 'outlined',
              onClick: onResetFilter
            }">
          </dxi-item>
        </dxo-toolbar>

        <!-- Templates -->
        <div *dxTemplate="let cell of 'Icon'" class="vh-center">
          <i class="material-icons md-18">{{ cell.data.document?.tags | getTag : 'icon' }}</i>
        </div>
        <div *dxTemplate="let cell of 'Attachment'">
          <span>{{ cell.value }}</span>
          <i *ngIf="cell.data.attachment" class="material-icons md-16">attach_file</i>
        </div>
        <div *dxTemplate="let cell of 'Labels'" class="labels" title="{{ cell.data.labelsTooltip }}">
          <span *ngFor="let label of cell.data.labels" class="label" [ngStyle]="{ 'background-color': label.color }">
            {{ label.name }}
          </span>
        </div>
        <div
          *dxTemplate="let cell of 'Labels in column'"
          class="labels labels-in-column"
          title="{{ cell.data.labelsTooltip }}">
          <span *ngFor="let label of cell.data.labels" class="label" [ngStyle]="{ 'background-color': label.color }">
            {{ label.name }}
          </span>
        </div>
        <div *dxTemplate="let cell of 'Progress'">
          <dx-progress-bar [min]="0" [max]="100" [value]="cell.data.progress" [showStatus]="false"></dx-progress-bar>
        </div>
        <div *dxTemplate="let cell of 'ProgressField'">
          <dx-progress-bar [min]="0" [max]="100" [value]="cell.value" [showStatus]="false"></dx-progress-bar>
        </div>
        <div *dxTemplate="let cell of 'parentDocumentsTemplate'">
          <div *ngFor="let parent of cell.data.parentDocuments">
            <div>
              <i class="material-icons md-12">{{ parent.parentDocumentIcon }}</i>
              {{ parent.parentDocumentType }}: {{ parent.parentDocumentSubject }}
            </div>
          </div>
        </div>
        <div *dxTemplate="let cell of 'childDocumentsTemplate'">
          <div *ngFor="let child of cell.data.childDocuments">
            <div>
              <i class="material-icons md-12">{{ child.childDocumentIcon }}</i>
              {{ child.childDocumentType }}: {{ child.childDocumentSubject }}
            </div>
          </div>
        </div>
        <div *dxTemplate="let cell of 'decisionDataTemplate'">
        </div>
        <ng-container *ngIf="settings?.summary">
          <dxo-summary>
            <dxi-total-item
              column="{{ summaryColumnName }}"
              summaryType="count"
              showInColumn="{{ summaryColumnName }}"
              cssClass="datagrid-document-summary"
              displayFormat="Dokumente: {0}">
            </dxi-total-item>
            <dxi-group-item summaryType="count"></dxi-group-item>
          </dxo-summary>
        </ng-container>
      </dx-data-grid>
      <dx-popover #popOverCreationColumn id="popOverCreationColumn" [(target)]="popoverElement">
        <div *dxTemplate="let data of 'content'">
          <div [innerHtml]="popoverContent | safeHtml"></div>
        </div>
      </dx-popover>
      <div
        class="items__preview items__preview_documents over-container"
        *ngIf="selectedDocument && listState.toolbar.isPreviewEnabled">
        <div class="header-container">
          <h3>{{ selectedDocument.template.caption }}</h3>
          <div class="header-container_buttons">
            <button type="submit" class="button-icon-header mr-2" (click)="onOpenDocument()">
              <i class="material-icons">zoom_out_map</i>
            </button>
            <button type="submit" class="button-icon-header mr-2" (click)="onClosePreview()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>

        <div class="content-container">
          <app-document-include [currentDocument]="selectedDocument" [editMode]="'ReadOnly'"></app-document-include>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="agenda-wrapper">
  <div class="agenda-items-block">
    <div class="agenda-header">
      <div *ngIf="collapseAll; else expandBlock" class="collapse-icon">
        <i id="collapseButton" (click)="onCollapseItems(false)" class="material-icons"> unfold_less </i>
      </div>
      <dx-tooltip target="#collapseButton" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">Alle Einträge zuklappen</div>
      </dx-tooltip>
      <ng-template #expandBlock>
        <div class="collapse-icon">
          <i id="expandButton" (click)="onCollapseItems(true)" class="material-icons"> unfold_more </i>
        </div>
        <dx-tooltip target="#expandButton" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data = data; of: 'content'">Alle Einträge aufklappen</div>
        </dx-tooltip>
      </ng-template>
      <div *ngIf="isCanUpdate" class="drag-icon">
        <i class="material-icons"> drag_indicator </i>
      </div>
      <div class="header-datagrid">
        <dx-data-grid
          #itemTopDataGridHeader
          class="itemDataGrid"
          [dataSource]="[{}]"
          [remoteOperations]="false"
          [showColumnHeaders]="false"
          [showRowLines]="false"
          [showColumnLines]="false"
          [rowAlternationEnabled]="false"
          [columnAutoWidth]="true"
          [columns]="topColumns"
          [showBorders]="false">
          <dxo-scrolling [showScrollbar]="false" [useNative]="false"></dxo-scrolling>
          <div *dxTemplate="let data of 'indexTemplate'">
            <div>#</div>
          </div>
          <div *dxTemplate="let data of 'subjectValueTemplate'">
            <span>
              {{ data.column.caption }}
            </span>
          </div>
          <div *dxTemplate="let data of 'fieldValueTemplate'">
            <span>
              {{ data.column.caption }}
            </span>
          </div>
          <div *dxTemplate="let data of 'speakersTemplate'">
            <span>
              {{ data.column.caption }}
            </span>
          </div>
          <div *dxTemplate="let data of 'durationTemplate'" class="ta-c">
            <span>
              {{ data.column.caption }}
            </span>
          </div>
          <div *dxTemplate="let data of 'WorkflowStatusTemplate'">
            <span>
              {{ data.column.caption }}
            </span>
          </div>
          <div *dxTemplate="let data of 'protocolTemplate'">
            <i id="protocol-header" class="material-icons protocol-icon">list_alt</i>
            <dx-tooltip
              target="#protocol-header"
              showEvent="mouseenter"
              hideEvent="mouseleave"
              [hideOnOutsideClick]="false">
              <div *dxTemplate="let data = data; of: 'content'">TOP-Protokoll</div>
            </dx-tooltip>
          </div>
          <div *dxTemplate="let data of 'actionTemplate'">
            <div class="ta-c">#</div>
          </div>
        </dx-data-grid>
      </div>
    </div>
    <div class="agenda-content" *ngIf="agendaItems?.length">
      <dx-sortable
        id="treeview-agenda"
        handle=".agenda-drag-icon"
        filter=".dx-treeview-item"
        group="cardsGroup"
        [data]="treelistItemsFiltered"
        [allowDropInsideItem]="isCanUpdate"
        [allowReordering]="isCanUpdate"
        (onDragStart)="onDragStart($event)"
        (onDragChange)="onDragChange($event)"
        (onDragEnd)="onDragEnd($event)"
        (onAdd)="onAddSubmission($event)">
        <dx-tree-view
          #treeViewAgendaItems
          id="treeViewAgendaItems"
          dataStructure="plain"
          displayExpr="name"
          [expandNodesRecursive]="false"
          [dataSource]="treelistItemsFiltered"
          keyExpr="id"
          parentIdExpr="parentId"
          itemTemplate="itemTemplate"
          (onContentReady)="onContentReadyTreeview($event)"
          (onItemRendered)="onItemRendered($event)"
          width="100%">
          <dxo-scrolling [showScrollbar]="false" [useNative]="false"></dxo-scrolling>

          <div
            *dxTemplate="let top of 'itemTemplate'; let index = index"
            [ngClass]="{
              'item-top-flex': top.type === 'top' || top.type === 'pause'
            }"
            class="item-{{ top.type }}">
            <span
              *ngIf="(top.type === 'top' || top.type === 'pause') && isCanUpdate"
              class="agenda-drag-icon md-24 material-icons">
              drag_indicator
            </span>
            <!-- dataGrid For TOP -->
            <dx-data-grid
              *ngIf="top.type === 'top'"
              #itemTopDataGrid
              id="{{ top.id }}"
              class="itemDataGrid"
              [ngClass]="{
                'item-top-notactive': top.isActive === false,
                'item-top-notvalid': top.isValid === false
              }"
              [dataSource]="[top]"
              [remoteOperations]="false"
              [showColumnHeaders]="false"
              [showRowLines]="false"
              [showColumnLines]="false"
              [rowAlternationEnabled]="false"
              [columnAutoWidth]="true"
              [columns]="topColumns"
              (onCellClick)="onRowClickDatagrid($event)"
              [showBorders]="false">
              <dxo-scrolling [showScrollbar]="false" [useNative]="false"></dxo-scrolling>

              <dxo-editing
                mode="cell"
                [allowUpdating]="top.isValid === true && top.isActive === true && isCanUpdate"
                [allowAdding]="false">
              </dxo-editing>

              <!-- index Template -->
              <div *dxTemplate="let cell of 'indexTemplate'">
                <div>
                  <span class="agenda-index">{{ top.position }}</span>
                </div>
              </div>

              <!-- SubjectValue Template -->
              <div *dxTemplate="let cell of 'subjectValueTemplate'" class="font-w-600">
                <span *ngIf="isMeetingMinutes" (click)="onOpenItem(top); $event.stopPropagation()">
                  {{ cell.value }}
                </span>
                <span *ngIf="!isMeetingMinutes">
                  {{ cell.value }}
                </span>
              </div>
              <!-- FieldValue Template -->
              <div *dxTemplate="let cell of 'fieldValueTemplate'" class="font-w-600">
                <span [innerHtml]="getFieldValue | apply : top : cell | safeHtml"> </span>
              </div>
              <div *dxTemplate="let cellInfo of 'fieldValueTemplateEditor'">
                <ng-container *ngIf="getColumnFeldConfig | apply : top : cellInfo as field">
                  <ng-container [ngSwitch]="field.type">
                    <ng-container *ngSwitchCase="'dxTextBox'">
                      <app-form-textbox
                        [(value)]="cellInfo.value"
                        [field]="field"
                        isEditing="true"
                        (valueChange)="onFieldValueChanged($event, cellInfo)"></app-form-textbox>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dxSelectBox'">
                      <app-form-selectbox
                        [(value)]="cellInfo.value"
                        [field]="field"
                        isEditing="true"
                        (valueChange)="onFieldValueChanged($event, cellInfo)"></app-form-selectbox>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dxTagBox'">
                      <app-form-tagbox
                        [(value)]="cellInfo.value"
                        [field]="field"
                        isEditing="true"
                        (valueChange)="onFieldValueChanged($event, cellInfo)"></app-form-tagbox>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dxNumberBox'">
                      <app-form-numberbox
                        [(value)]="cellInfo.value"
                        [field]="field"
                        isEditing="true"
                        (valueChange)="onFieldValueChanged($event, cellInfo)"></app-form-numberbox>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dxDateBox'">
                      <app-form-datebox
                        [(value)]="cellInfo.value"
                        [field]="field"
                        isEditing="true"
                        (valueChange)="onFieldValueChanged($event, cellInfo)"></app-form-datebox>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

              <!-- Workflow Status Template -->
              <div *dxTemplate="let data of 'WorkflowStatusTemplate'">
                <span id="workflow-status--{{ top.id }}">
                  {{ getWorkflowStatus | apply : top }}
                </span>
                <dx-tooltip
                  target="#workflow-status--{{ top.id }}"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">Workflow Status</div>
                </dx-tooltip>
              </div>

              <!-- Protokoll Template -->
              <div *dxTemplate="let data of 'protocolTemplate'">
                <i
                  id="protokol-icon--{{ top.id }}"
                  *ngIf="top.protocol"
                  (click)="openDocument(top.protocol, 'top-protocol'); $event.stopPropagation()"
                  class="material-icons"
                  >done</i
                >
                <dx-tooltip
                  target="#protokol-icon--{{ top.id }}"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  [hideOnOutsideClick]="false">
                  <div *dxTemplate="let data = data; of: 'content'">Protokoll erstellt</div>
                </dx-tooltip>
              </div>

              <!-- Referenten Template -->
              <div *dxTemplate="let data of 'speakersTemplate'" class="agenda-speakers">
                <span class="agenda-speakers-icon" *ngIf="!top?.speakers?.length && isCanUpdate">
                  <i class="material-icons md-22">person_add</i>
                </span>
                <span>
                  {{ top.speakers | mapAuthorName | async }}
                </span>
              </div>

              <!-- Referenten Template Editor -->
              <div *dxTemplate="let cellInfo of 'speakersTemplateEditor'">
                <ng-container *ngIf="getFieldSpeakers | apply as field">
                  <app-form-tagbox
                    [(value)]="cellInfo.value"
                    [field]="field"
                    isEditing="true"
                    (valueChange)="onFieldValueChanged($event, cellInfo)"
                    [cell]="cellInfo"></app-form-tagbox>
                </ng-container>
              </div>

              <!-- Zeit Template -->
              <div *dxTemplate="let data of 'durationTemplate'">
                <span
                  *ngIf="
                    data.column.durationOptions.showDuration &&
                    !data.column.durationOptions.showStartTime &&
                    !data.column.durationOptions.showEndTime
                  "
                  id="top-duration-{{ top.id }}"
                  class="agenda-item-time">
                  {{ top.duration }} Min.</span
                >
                <span
                  *ngIf="
                    data.column.durationOptions.showDuration &&
                    data.column.durationOptions.showStartTime &&
                    data.column.durationOptions.showEndTime
                  "
                  id="top-duration-{{ top.id }}"
                  class="agenda-item-time">
                  <span>{{ top.duration }} Min.</span>
                  <span>({{ top.startTime | date : 'HH:mm' }} &ndash; {{ top.endTime | date : 'HH:mm' }})</span>
                </span>
                <span
                  *ngIf="
                    data.column.durationOptions.showDuration &&
                    data.column.durationOptions.showStartTime &&
                    !data.column.durationOptions.showEndTime
                  "
                  id="top-duration-{{ top.id }}"
                  class="agenda-item-time">
                  <span>{{ top.duration }} Min.</span>
                  <span>{{ top.startTime | date : 'HH:mm' }}</span>
                </span>
                <span
                  *ngIf="
                    data.column.durationOptions.showDuration &&
                    !data.column.durationOptions.showStartTime &&
                    data.column.durationOptions.showEndTime
                  "
                  id="top-duration-{{ top.id }}"
                  class="agenda-item-time">
                  <span>{{ top.duration }} Min.</span>
                  <span>&ndash; {{ top.endTime | date : 'HH:mm' }}</span>
                </span>
                <dx-popover
                  *ngIf="top.isActive === true && top.isValid === true && isCanUpdate && !isMeetingMinutes"
                  target="#top-duration-{{ top.id }}"
                  showEvent="click"
                  position="top"
                  [shading]="false"
                  shadingColor="rgba(0, 0, 0, 0.5)">
                  <div *dxTemplate="let popoverContent of 'content'">
                    <div *ngIf="!(itemHasChild | apply : top)">
                      <dx-button
                        icon="plus"
                        (onClick)="increaseTime(top)"
                        [elementAttr]="{ class: 'agenda-item-time__button' }">
                      </dx-button>
                      <dx-button icon="minus" (onClick)="decreaseTime(top)" class="agenda-item-time__button">
                      </dx-button>
                    </div>
                    <div *ngIf="itemHasChild | apply : top">
                      <span>Bitte änderen Sie die Zeit für die Unter-TOPs</span>
                    </div>
                  </div>
                </dx-popover>
              </div>

              <!-- Action Column Template -->
              <div *dxTemplate="let data of 'actionTemplate'" class="agenda-action">
                <dx-menu
                  *ngIf="isCanUpdate"
                  #menu
                  [items]="top.itemTOPAction"
                  displayExpr="text"
                  orientation="horizontal"
                  [hideSubmenuOnMouseLeave]="false"
                  (onItemRendered)="itemMenuRendered($event)"
                  (onItemClick)="selectAgendaItemAction($event, top)">
                </dx-menu>
                <!--               <dx-drop-down-button
                *ngIf="isCanUpdate"
                icon="material-icons more_vert md-24"
                class="overflow-menu"
                [showArrowIcon]="false"
                stylingMode="text"
                [dropDownOptions]="{ width: 'auto' }"
                displayExpr="text"
                [items]="top.itemTOPAction"
                (onItemClick)="selectAgendaItemAction($event, top)"></dx-drop-down-button> -->
              </div>
              <div *dxTemplate="let data of 'deleteTemplate'" class="ta-c">
                <i (click)="removeAgendaItem($event, top)" class="material-icons md-20">delete</i>
              </div>
            </dx-data-grid>

            <!-- dataGrid For Submission -->
            <div *ngIf="top.type === 'top-submissions'" class="top-children-item">
              <div *ngFor="let list of top.submissionsItems; let i = index">
                <dx-sortable
                  id="sortable-{{ top.id }}"
                  class="sortable-submissions"
                  [itemOrientation]="displayColumns === 1 ? 'vertical' : 'horizontal'"
                  dropFeedbackMode="indicate"
                  dragTemplate="dragTemplateItem"
                  group="cardsGroup"
                  [data]="list"
                  [allowDropInsideItem]="false"
                  [allowReordering]="isCanUpdate && top.isActive"
                  (onDragStart)="onSubmissionDragStart($event, top)"
                  (onDragChange)="onSubmissionDragChange($event, top)"
                  (onReorder)="onSubmissionDrop($event, top)"
                  (onAdd)="onSubmissionDrop($event, top)"
                  [ngStyle]="{
                    'grid-template-columns':
                      displayColumns > 0
                        ? 'repeat(auto-fill, max(' + displayColumns + 'px, 100% /' + displayColumns + '))'
                        : null
                  }">
                  <div class="sortable-submission-item" *ngFor="let child of list">
                    <div
                      class="submission-item-workflow-status types-selectbox_background-{{
                        getWorkflowStatusColor | apply : child
                      }}"></div>
                    <div class="submission-item-position" (click)="onOpenItem(child); $event.stopPropagation()">
                      {{ child.position }}
                    </div>
                    <div class="submission-item-header" (click)="onOpenItem(child); $event.stopPropagation()">
                      <div class="submission-item-title">
                        {{
                          (getFieldValueFromDocument | apply : child.document : submissionFields.subjectField) ||
                            (getFieldValueFromDocument | apply : child.document : 'template.caption')
                        }}
                      </div>
                      <div class="submission-item-subtitle" *ngIf="submissionFields.subField">
                        {{ getFieldValueFromDocument | apply : child.document : submissionFields.subField }}
                      </div>
                    </div>
                    <div class="submission-item-decision">
                      <span
                        *ngIf="hasDecision | apply : child | async"
                        (click)="openDocument(child, 'decision'); $event.stopPropagation()">
                        <i class="material-icons">bookmark</i>
                        {{ getDecisionNumberValue | apply : child | async }}
                      </span>
                    </div>
                    <div class="submission-item-tasks">
                      <div *ngIf="!child.tasks?.length"><i class="material-icons">alarm_on</i></div>
                      <div *ngIf="child.tasks?.length" style="position: relative">
                        <dx-drop-down-button
                          icon="material-icons alarm_on md-24"
                          class="overflow-menu"
                          [showArrowIcon]="false"
                          stylingMode="text"
                          [dropDownOptions]="{ width: 'auto' }"
                          [items]="child.tasks"
                          itemTemplate="taskItemTemplate"
                          (onItemClick)="onOpenTask($event, top, child)">
                          <div *dxTemplate="let taskItem of 'taskItemTemplate'">
                            <div class="dx-item-content dx-scheduler-appointment-content" style="display: flex">
                              <div class="dx-scheduler-agenda-appointment-left-layout">
                                <div
                                  class="dx-scheduler-agenda-appointment-marker dx-scheduler-appointment-has-resource-color"
                                  [ngClass]="
                                    'priority-' +
                                    (getFieldValueFromDocument | apply : taskItem.document : taskFields.priorityField)
                                  "></div>
                              </div>
                              <div class="dx-scheduler-agenda-appointment-right-layout" style="margin-left: 10px">
                                <div
                                  style="
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    font-weight: 700;
                                    font-size: 13px;
                                    margin-bottom: 5px;
                                  ">
                                  {{
                                    (getFieldValueFromDocument | apply : taskItem.document : taskFields.subjectField) ||
                                      (getFieldValueFromDocument | apply : taskItem.document : 'template.caption')
                                  }}
                                </div>
                                <div class="dx-scheduler-appointment-content-details" style="display: flex">
                                  <div style="opacity: 0.7; margin-right: 10px">
                                    <b>Frist:</b>
                                    {{
                                      getFieldValueFromDocument
                                        | apply : taskItem.document : taskFields.enddateField
                                        | date : 'dd.MM.yyyy'
                                    }}
                                  </div>
                                  <div class="dx-scheduler-appointment-resource-list" style="margin-left: auto">
                                    <div class="dx-scheduler-appointment-resource-item" style="display: flex">
                                      <b>Referenten:</b>
                                      <div class="dx-scheduler-appointment-resource-item-value">
                                        {{
                                          getFieldValueFromDocument
                                            | apply : taskItem.document : taskFields.speakersField
                                            | mapAuthorName
                                            | async
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </dx-drop-down-button>
                        <span class="badge">{{ child.tasks?.length }}</span>
                      </div>
                    </div>

                    <div class="submission-item-actions">
                      <dx-menu
                        *ngIf="isCanUpdate && child.isActive === true"
                        #menu
                        [items]="child.itemSubmissionAction"
                        displayExpr="text"
                        orientation="horizontal"
                        [hideSubmenuOnMouseLeave]="false"
                        (onItemRendered)="itemMenuRendered($event)"
                        (onItemClick)="selectAgendaItemSubmissionAction($event, top, child)">
                      </dx-menu>
                    </div>
                  </div>
                  <div *dxTemplate="let e of 'dragTemplateItem'">
                    <div>{{ e.itemData?.document?.fields?.subject?.value }}</div>
                  </div>
                </dx-sortable>
              </div>
            </div>

            <!-- dataGrid For Protocol -->
            <div *ngIf="top.type === 'top-protocol'" style="display: flex">
              <div style="width: 20%; align-self: center">
                {{
                  (getFieldValueFromDocument | apply : top.document : protocolFields.subjectField) ||
                    (getFieldValueFromDocument | apply : top.document : 'template.caption')
                }}
              </div>
              <div>
                <dx-html-editor
                  valueType="html"
                  readOnly="true"
                  [elementAttr]="{ style: 'border: none' }"
                  value="{{
                    getFieldValueFromDocument | apply : top.document : protocolFields.bodyField
                  }}"></dx-html-editor>
              </div>
            </div>

            <!-- dataGrid For Break -->
            <dx-data-grid
              *ngIf="top.type === 'pause'"
              #itemBreakItemDataGrid
              id="itemBreakItemDataGrid"
              class="itemDataGrid item-break"
              [dataSource]="[top]"
              [remoteOperations]="false"
              [showColumnHeaders]="false"
              [showRowLines]="false"
              [showColumnLines]="false"
              [rowAlternationEnabled]="false"
              [columnAutoWidth]="true"
              [columns]="breakColumns"
              [showBorders]="false">
              <dxo-editing mode="cell" [allowUpdating]="isCanUpdate" [allowAdding]="false" [allowDeleting]="false">
              </dxo-editing>
              <dxo-scrolling [showScrollbar]="false" [useNative]="false"></dxo-scrolling>

              <div *dxTemplate="let data of 'indexTemplate'">
                <i class="material-icons md-20 agenda-break-icon">coffee</i>
              </div>
              <!-- SubjectValue Template -->
              <div *dxTemplate="let data of 'subjectValueTemplate'" class="font-w-600">
                <span *ngIf="isMeetingMinutes" (click)="onOpenItem(top); $event.stopPropagation()">
                  {{ data.value }}
                </span>
                <span *ngIf="!isMeetingMinutes">
                  {{ data.value }}
                </span>
              </div>
              <div *dxTemplate="let data of 'fieldValueTemplate'" class="font-w-600">
                <span>
                  {{ getFieldValue | apply : top : data }}
                </span>
              </div>
              <div *dxTemplate="let cellInfo of 'fieldValueTemplateEditor'">
                <dx-text-box [value]="cellInfo.value" (onValueChanged)="cellInfo.setValue($event.value)"></dx-text-box>
              </div>
              <div *dxTemplate="let data of 'speakersTemplate'"></div>
              <div *dxTemplate="let cellInfo of 'speakersTemplateEditor'"></div>
              <!-- Zeit Template -->
              <div *dxTemplate="let data of 'durationTemplate'">
                <span
                  *ngIf="
                    data.column.durationOptions.showDuration &&
                    !data.column.durationOptions.showStartTime &&
                    !data.column.durationOptions.showEndTime
                  "
                  id="top-duration-{{ top.id }}"
                  class="agenda-item-time">
                  {{ top.duration }} Min.</span
                >
                <span
                  *ngIf="
                    data.column.durationOptions.showDuration &&
                    data.column.durationOptions.showStartTime &&
                    data.column.durationOptions.showEndTime
                  "
                  id="top-duration-{{ top.id }}"
                  class="agenda-item-time">
                  <span>{{ top.duration }} Min.</span>
                  <span>({{ top.startTime | date : 'HH:mm' }} &ndash; {{ top.endTime | date : 'HH:mm' }})</span>
                </span>
                <span
                  *ngIf="
                    data.column.durationOptions.showDuration &&
                    data.column.durationOptions.showStartTime &&
                    !data.column.durationOptions.showEndTime
                  "
                  id="top-duration-{{ top.id }}"
                  class="agenda-item-time">
                  <span>{{ top.duration }} Min.</span>
                  <span>{{ top.startTime | date : 'HH:mm' }}</span>
                </span>
                <span
                  *ngIf="
                    data.column.durationOptions.showDuration &&
                    !data.column.durationOptions.showStartTime &&
                    data.column.durationOptions.showEndTime
                  "
                  id="top-duration-{{ top.id }}"
                  class="agenda-item-time">
                  <span>{{ top.duration }} Min.</span>
                  <span>&ndash; {{ top.endTime | date : 'HH:mm' }}</span>
                </span>
                <dx-popover
                  *ngIf="top.isActive === true && top.isValid === true && isCanUpdate && !isMeetingMinutes"
                  target="#top-duration-{{ top.id }}"
                  showEvent="click"
                  position="top"
                  [width]="200"
                  [shading]="false"
                  shadingColor="rgba(0, 0, 0, 0.5)">
                  <div
                    *dxTemplate="let popoverContent of 'content'"
                    style="display: flex; justify-content: space-between; align-items: center">
                    <div *ngIf="!(itemHasChild | apply : top)">
                      <dx-button icon="plus" (onClick)="increaseTime(top)" style="width: 60px; margin: 5px">
                      </dx-button>
                      <dx-button icon="minus" (onClick)="decreaseTime(top)" style="width: 60px; margin: 5px">
                      </dx-button>
                    </div>
                    <div *ngIf="itemHasChild | apply : top">
                      <span>Bitte änderen Sie die Zeit für die Unter-TOPs</span>
                    </div>
                  </div>
                </dx-popover>
              </div>
              <div *dxTemplate="let data of 'WorkflowStatusTemplate'"></div>
              <div *dxTemplate="let data of 'protocolTemplate'"></div>
              <div *dxTemplate="let data of 'actionTemplate'" class="ta-c">
                <div id="AgendaItemRemove" class="agenda-column__actions__delete" *ngIf="isCanUpdate">
                  <i (click)="removeAgendaItem(top)" click class="material-icons md-20">delete</i>
                </div>
              </div>
            </dx-data-grid>
          </div>
        </dx-tree-view>
      </dx-sortable>

      <div class="agenda-footer-time" *ngIf="!isMeetingMinutes">
        Sitzungsdauer: {{ agendaTotalTime }} - Voraussichtliches Sitzungsende: {{ agendaEndTime }} Uhr
      </div>
    </div>
  </div>
  <div class="agenda-actions-button" (click)="showAgendaActions($event)" *ngIf="!isMeetingMinutes && isCanUpdate">
    <i class="dx-icon dx-icon-add" style="font-size: 18px"></i>
  </div>
</div>

<!-- Placeholder for agenda items -->
<ng-template #placeholder>
  <div class="agenda-column agenda-column__approval">
    <i class="material-icons">&nbsp;</i>
  </div>
</ng-template>

<dx-action-sheet
  id="AgendaItemActionSheet"
  [showTitle]="false"
  [usePopover]="true"
  [target]="agendaActionsTarget"
  [width]="'auto'"
  [dataSource]="actionsDataSource"
  [(visible)]="agendaActionsVisible"
  (onItemRendered)="addClassToPopupActions($event)">
</dx-action-sheet>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für AgendaItems: Lesen oder Bearbeiten -->
<!-- ----------------------------------------------------------------------------------------------- -->
<app-document-editor [parentDocument]="agendaDocument" (dialogResult)="documentEditorResult($event)">
</app-document-editor>
<app-workflow-agenda
  *ngIf="selectedWorkflowActionDocument && selectedWorkflowAction"
  (dialogResult)="workflowAgendaResult($event)"
  [document]="selectedWorkflowActionDocument"
  [action]="selectedWorkflowAction"
  [agendaDocument]="agendaDocument"></app-workflow-agenda>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Vorlagenliste -->
<!-- ----------------------------------------------------------------------------------------------- -->

<ng-container *ngIf="popupSubmissionsListVisible">
  <dx-popup
    [(visible)]="popupSubmissionsListVisible"
    width="75vw"
    [height]="600"
    [showTitle]="true"
    [title]="popupSubmissionsListTitle"
    [showCloseButton]="false"
    [fullScreen]="false"
    [dragEnabled]="true"
    [hideOnOutsideClick]="false"
    [shading]="true"
    shadingColor="rgba(0, 0, 50, 0.5)"
    [toolbarItems]="[
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        disabled: isSelectedSubmissionList,
        options: { icon: 'add', text: 'Übernehmen', visible: 'true' },
        onClick: onSubmitSubmissionList
      },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
        onClick: onCancelSubmissionList
      }
    ]">
    <div *dxTemplate="let data of 'content'">
      <app-dynamic-list
        [documentType]="dynamicListName"
        [embeddedMode]="false"
        [listener]="dynamicGridListener"
        [noState]="true"
        [selectionMode]="selectionModeSubmissionList"
        [additionalSettings]="dynamicGridSubmissionList"
        (emitter)="eventDynamicListResultSubmissionList($event)">
      </app-dynamic-list>
    </div>
  </dx-popup>
</ng-container>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Freien TOPs -->
<!-- ----------------------------------------------------------------------------------------------- -->

<ng-container *ngIf="popupFreeTopsListVisible">
  <dx-popup
    width="75vw"
    shadingColor="rgba(0, 0, 50, 0.5)"
    [visible]="popupFreeTopsListVisible"
    [height]="600"
    [showTitle]="true"
    [title]="popupFreeTopsListTitle"
    [showCloseButton]="false"
    [fullScreen]="false"
    [dragEnabled]="true"
    [hideOnOutsideClick]="false"
    [shading]="true"
    [toolbarItems]="[
      {
        widget: 'dxButton',
        location: 'after',
        disabled: isSelectedFreeTOPList,
        toolbar: 'bottom',
        options: { icon: 'add', text: 'Kopie auf Agenda nehmen', visible: 'true' },
        onClick: onSubmitFreeTOPsList
      },
      {
        name: 'Delete',
        widget: 'dxButton',
        location: 'after',
        disabled: isSelectedFreeTOPList,
        toolbar: 'bottom',
        options: { icon: 'clear', text: 'Freien TOP löschen', hint: 'Freien TOP löschen' },
        onClick: onDeleteFreeTOPsList
      },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
        onClick: onCancelFreeTOPsList
      }
    ]">
    <div *dxTemplate="let data of 'content'">
      <app-dynamic-list
        [documentType]="'top'"
        [embeddedMode]="false"
        [listener]="dynamicGridListener"
        [noState]="true"
        [selectionMode]="'multiple'"
        [additionalSettings]="dynamicGridFreeTOPList"
        (emitter)="eventDynamicListResultFreeTOPsList($event)">
      </app-dynamic-list>
    </div>
  </dx-popup>
</ng-container>
<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Referenced TOPs -->
<!-- ----------------------------------------------------------------------------------------------- -->

<ng-container *ngIf="popupReferencedFreeTopsListVisible">
  <dx-popup
    width="75vw"
    shadingColor="rgba(0, 0, 50, 0.5)"
    [visible]="popupReferencedFreeTopsListVisible"
    [height]="600"
    [showTitle]="true"
    [title]="popupReferencedTopsListTitle"
    [showCloseButton]="false"
    [fullScreen]="false"
    [dragEnabled]="true"
    [hideOnOutsideClick]="false"
    [shading]="true"
    [toolbarItems]="[
      {
        widget: 'dxButton',
        location: 'after',
        disabled: isSelectedReferencedTOPList,
        toolbar: 'bottom',
        options: { icon: 'add', text: 'Auf Agenda nehmen', visible: 'true' },
        onClick: onSubmitReferencedTOPsList
      },
      {
        name: 'Delete',
        widget: 'dxButton',
        location: 'after',
        disabled: isSelectedReferencedTOPList,
        toolbar: 'bottom',
        options: { icon: 'clear', text: 'Verknüpfung lösen', hint: 'Verknüpfung lösen' },
        onClick: onRemoveReferencedTOPsList
      },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
        onClick: onCancelReferencedTOPsList
      }
    ]">
    <div *dxTemplate="let data of 'content'">
      <app-dynamic-list
        [documentType]="'top'"
        [embeddedMode]="false"
        [listener]="dynamicGridListener"
        [noState]="true"
        [selectionMode]="'multiple'"
        [additionalSettings]="dynamicGridReferencedTOPList"
        (emitter)="eventDynamicListResultReferencedTOPsList($event)">
      </app-dynamic-list>
    </div>
  </dx-popup>
</ng-container>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Freien TOPs -->
<!-- ----------------------------------------------------------------------------------------------- -->

<ng-container *ngIf="popupTOPsFromAgendaListVisible">
  <dx-popup
    width="75vw"
    shadingColor="rgba(0, 0, 50, 0.5)"
    [visible]="popupTOPsFromAgendaListVisible"
    [height]="600"
    [showTitle]="true"
    [title]="popupTOPsFromAgendaListTitle"
    [showCloseButton]="false"
    [fullScreen]="false"
    [dragEnabled]="true"
    [hideOnOutsideClick]="false"
    [shading]="true"
    [toolbarItems]="[
      {
        widget: 'dxButton',
        location: 'after',
        disabled: isSelectedTOPsFromAgendaList,
        toolbar: 'bottom',
        options: { icon: 'add', text: 'Kopie auf Agenda nehmen', visible: 'true' },
        onClick: onSubmitTOPsFromAgendaList
      },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
        onClick: onCancelTOPsFromAgendaList
      }
    ]">
    <div *dxTemplate="let data of 'content'">
      <dx-data-grid
        #freeTopSelectionGrid
        [dataSource]="popupTOPsFromAgendaList"
        [height]="460"
        [showBorders]="true"
        [showColumnLines]="true"
        [allowColumnResizing]="true"
        [hoverStateEnabled]="false"
        [selectedRowKeys]="[]"
        [columnAutoWidth]="true"
        (onSelectionChanged)="onSelectionChangedTopsFromAgenda($event)"
        (onContentReady)="onContentReadyOldTops($event)"
        (onToolbarPreparing)="onToolbarPreparingOldTops($event)">
        <!-- Grid Editing settings -->
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="popupTOPsFromAgendaListExpanded" expandMode="buttonClick"></dxo-grouping>

        <!-- columns -->
        <dxi-column
          dataField="agendaType"
          groupCellTemplate="categoryGroup"
          caption="Sitzungstyp"
          [width]="'auto'"
          [groupIndex]="0"></dxi-column>
        <dxi-column
          dataField="agendaStartDate"
          groupCellTemplate="categoryGroupAgendaSubject"
          caption="Sitzungsthema"
          [width]="'auto'"
          [groupIndex]="1"></dxi-column>

        <dxi-column
          cellTemplate="subjectWithAttachment"
          [allowHeaderFiltering]="false"
          [width]="30"
          alignment="center"
          [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column caption="Thema" dataField="subject" [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Erstellt am"
          dataField="document.creation.timestamp"
          dataType="date"
          [width]="100"
          [format]="{ type: 'dd.MM.yyyy' }"
          [allowFiltering]="false"></dxi-column>

        <dxi-column
          caption="Erstellt durch"
          dataField="creatordata"
          [allowSorting]="true"
          [allowHeaderFiltering]="true"
          [allowFiltering]="false"
          [encodeHtml]="false"
          [width]="250">
        </dxi-column>

        <!-- Templates -->
        <div *dxTemplate="let cell of 'cellUser'">
          <div [innerHtml]="(cell.data.creation.user | getNameData | async).avatarName | safeHtml"></div>
        </div>

        <div *dxTemplate="let cell of 'subjectWithAttachment'">
          <i *ngIf="cell.data.document | hasAttachment" class="material-icons md-16">attach_file</i>
        </div>
        <div *dxTemplate="let cell of 'categoryGroup'">
          <div i18n class="list-categories">
            {{ cell.text ? cell.text : '(nicht kategorisiert)' }}
          </div>
        </div>
        <div *dxTemplate="let cell of 'categoryGroupAgendaSubject'">
          <div i18n class="list-categories">
            {{
              cell.data.collapsedItems
                ? cell.data.collapsedItems[0].agendaSubject
                : cell.data.items
                ? cell.data.items[0].agendaSubject
                : '(nicht kategorisiert)'
            }}
          </div>
        </div>
      </dx-data-grid>
    </div>
  </dx-popup>
</ng-container>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Timer -->
<!-- ----------------------------------------------------------------------------------------------- -->

<ng-container *ngIf="currentItem">
  <dx-popup
    [visible]="currentItem"
    [title]="timerTitle"
    width="200"
    [maxHeight]="popupHeight"
    [position]="{ at: 'right top', offset: '-150 100', of: '#document-base' }"
    [showTitle]="true"
    [showCloseButton]="false"
    [hideOnOutsideClick]="false"
    [shading]="false">
    <div *dxTemplate="let data of 'content'">
      <div class="ta-c">
        <span style="font-weight: 600">verbleibende Zeit</span>
        <p
          style="font-size: 40px; font-weight: 600"
          [ngClass]="{
            'timer-exceeded': exceededTime
          }">
          {{ countdown }}
        </p>

        <dx-button text="Nächste" icon="arrowright" [visible]="exceededTime" (onClick)="onNextTimer()"> </dx-button>
      </div>
    </div>
  </dx-popup>
</ng-container>

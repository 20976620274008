import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SiamListItem } from '@interfaces/siam';
import { LoggerService } from '@services/logger.service';
import { SiamList } from '@interfaces/siamList';

interface IGraphQLResponse {
  listByName?: SiamList;
  lists?: SiamList[];
}

@Injectable({
  providedIn: 'root'
})
export class ListsApi {
  readonly apiBaseUrl: string;
  readonly apiBaseUrlV11: string;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private logger: LoggerService,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.apiBaseUrl = `${this.baseUrl}api/lists`;
    this.apiBaseUrlV11 = `${this.baseUrl}api/v1.1/lists`;
  }

  getList(name: string): Observable<SiamList> {
    const query = gql`
      query ($name: String!) {
        listByName(name: $name) {
          id
          name
          description
          tags
          properties
          entries {
            id
            name: label
            label
            value
            properties
          }
        }
      }
    `;

    return this.apollo
      .query<IGraphQLResponse>({
        query,
        variables: { name }
      })
      .pipe(
        map(({ data }) => {
          if (data.listByName) {
            return new SiamList(data.listByName);
          }
          return null;
        })
      );
  }

  /**
   *  Get Lists  by graphQL
   */
  getListsWithEntries(): Observable<SiamList[]> {
    const query = gql`
      query {
        lists {
          id
          name
          description
          tags
          properties
          entries {
            name: label
            label
            value
            properties
          }
        }
      }
    `;

    return this.apollo
      .query<IGraphQLResponse>({
        query
      })
      .pipe(map(({ data }) => data.lists.map(list => new SiamList(list))));
  }

  /**
   * returns a single lists info by id
   *
   * @param name lists ID
   */
  getListEntries(name: string): Observable<SiamListItem[]> {
    return this.http.get<SiamListItem[]>(`${this.apiBaseUrl}/${encodeURIComponent(name)}`).pipe(
      tap({
        error: error => {
          this.logger.error('Could not read a list by name: {@error}', error);
        }
      })
    );
  }
  /**
   * returns a single lists info by id
   *
   * @param id lists ID
   */
  getListItems(id: string): Observable<SiamList> {
    return this.http
      .get<SiamListItem[]>(`${this.apiBaseUrlV11}/${id}`, {
        observe: 'response',
        responseType: 'json'
      })
      .pipe(
        tap({
          error: error => {
            this.logger.error('Could not read a list by name: {@error}', error);
          }
        }),
        map(response => {
          const list = new SiamList();
          list.id = response.headers.get('x-ventuno-siam-list-id');
          list.name = response.headers.get('x-ventuno-siam-list-name');
          list.description = decodeURIComponent(response.headers.get('x-ventuno-siam-list-description'));
          list.tags = response.headers.get('x-ventuno-siam-list-tags')?.split(',');
          list.entries = response.body;
          return list;
        })
      );
  }

  /**
   * returns a single lists info by id
   *
   * @param name lists ID
   */
  deleteList(name: string): Observable<unknown> {
    return this.http.delete<SiamList>(`${this.apiBaseUrl}/${encodeURIComponent(name)}`).pipe(
      tap({
        error: error => {
          this.logger.error('Could not delete the list: {@error}', error);
        }
      })
    );
  }

  /**
   * save or update the list
   *
   * @param list
   */
  saveList(list: SiamList): Observable<SiamList> {
    return this.http.put<SiamList>(`${this.apiBaseUrlV11}/${encodeURIComponent(list.name)}`, list).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Aktualisieren der Liste: {@error}', error);
        }
      })
    );
  }
}

export const siamConst = {
  currentUser: 'current_user_info',
  currentVersion: 'current_version',
  currentUserTimestamp: 'current-user-info-timestamp',
  breakpointMobil: 768,
  group: 'Feldgruppe',
  defaultWorkflowId: 'ef219817-b304-4767-ab48-b9576ac834c8',
  defaultBallotId: '48fc67db-ff7f-4d3d-8508-92e1c6c6266a',
  globalLabelsListName: 'internal_globalLabels',
  globalTagsListName: 'internal_globalTags',
  dynamicListsListName: 'internal_dynamicLists',
  dynamicCardsListName: 'internal_dynamicCards',
  siamAssigneeTag: 'siam-assignee',
  distrubitionTag: 'Verteiler',
  treelistTag: 'Treelist',
  homePageConfig: 'homePageConfig',
  startPageConfig: 'startPageConfig',
  parentTag: 'system:document:dependency:parent',
  childTag: 'system:document:dependency:child',
  patternTag: 'system:document:pattern',
  patternTemplateIdTag: 'system:document:pattern:template:id',
  conditionAgendaExistsTag: '$app:agilicision:agenda:exists',
  conditionChildExistsTag: '$app:agilicision:child:exists:type',
  conditionParentExistsTag: '$app:agilicision:parent:exists:type',
  appTitle: 'AgiliCision',
  appLogoSvg: 'assets/branding/appLogo.svg',
  appLogoPng: 'assets/branding/appLogo.png',
  agendaField: '--agendaitems',
  agendaDocumentId: '--siam-agendaDocID',
  topStartTime: 'agendaitem-starttime',
  topEndTime: 'agendaitem-endtime',
  topDuration: 'agendaitem-duration',
  topSpeakers: 'agendaitem-speakers',
  topPosition: 'agendaitem-position',
  topActive: 'agendaitem-active',
  topType: 'agendaitem-type',
  agendaAllSpeakers: 'agendaitem-all-speakers',
  decisionField: '$system:decision',
  agendaFieldId: '--agenda-field-id',
  cloneFieldId: '--clone-top-id'
};

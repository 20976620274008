import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { EMPTY, lastValueFrom, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AnimationConfig } from 'devextreme/animation/fx';
import {
  INameSelectorDialogInclude,
  INameSelectorDialogRecord,
  INameSelectorDialogSettings,
  IPermissionTarget,
  IRole,
  ISelectableNames,
  IUser,
  TNameSelectorDialogMode,
  TNameSelectorDialogType,
  TSelectableNames
} from '@interfaces/siam';
import { RoleService } from '@services/roles.service';
import { UserService } from '@services/user.service';
import { ComponentNotificationComponent } from 'src/app/_core/components/notify/component-notification/component-notification.component';
import { LoggerService } from '@services/logger.service';
import { TemplateService } from '@services/template.service';
import { CellClickEvent, CellPreparedEvent, RowClickEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';

interface IUserRole {
  type: string;
  id: string;
  data: IUser | IRole;
}

@Component({
  selector: 'app-name-selector',
  templateUrl: './name-selector.component.html',
  styleUrls: ['./name-selector.component.scss']
})
export class NameSelectorComponent implements OnDestroy {
  @ViewChild('nameSelectorGrid') nameSelectorGrid: DxDataGridComponent;
  @ViewChild(forwardRef(() => ComponentNotificationComponent)) notificationComponent: ComponentNotificationComponent;

  @Output() dialogHidden = new EventEmitter<boolean>();
  @Output() dialogResult = new EventEmitter<(IUser | IRole)[]>();

  @Input() requiredSelection = true;
  @Input() dialogOpen = false;

  minSelectionText = '';
  title = 'Personenauswahl';
  filteringEnabled = true;
  fullScreen = false;
  isOkButtonDisabled = true;
  settings: INameSelectorDialogSettings;
  selectionMode: TNameSelectorDialogMode = 'multiple';
  selectType: TNameSelectorDialogType = 'Personen';
  include?: INameSelectorDialogInclude[];
  exclude?: INameSelectorDialogInclude[];
  usersAndRoles: { type: string; id: string; data: IRole | IUser }[] = [];
  datasource: DataSource;
  selectedRecords: string[] = [];
  checkBoxesMode: string;
  editorElement: HTMLElement;
  hideAnimation: AnimationConfig;
  showAnimation: AnimationConfig;

  private selected: INameSelectorDialogRecord[] = [];

  #destroyable$ = new Subject<void>();

  constructor(
    private _logger: LoggerService,
    private _roleService: RoleService,
    private _userService: UserService,
    private _templateService: TemplateService
  ) {
    this.getUsersAndRoles()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe(() => {
        this._logger.debug('ALLE User und Rollen eingelesen.');
      });
    this.showAnimation = { type: 'pop', duration: 400, delay: 100, from: { scale: 0 } };
    this.hideAnimation = {
      type: 'pop',
      duration: 300,
      to: {
        opacity: 0,
        scale: 0.55
      },
      from: {
        opacity: 1,
        scale: 1
      }
    };
  }

  initDataStore(): void {
    this.usersAndRoles = [];
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  calculateCellNameValue(e: CellClickEvent<IUser>): string {
    if (e.data.profile) {
      return `${e.data.profile.surname}, ${e.data.profile.forename}`;
    }
    return e.data.name;
  }

  /**
   * show the nameselector-dialog
   */
  show(settings?: INameSelectorDialogSettings): void {
    this.settings = settings;
    if (settings) {
      if (settings.minSelection) {
        const must = settings.minSelection > 1 ? 'müssen' : 'muss';
        this.minSelectionText = `Hinweis: Es ${must} mindestens ${settings.minSelection} Empfänger ausgewählt werden!`;
      }
      this.title = settings.title;
      this.fullScreen = !!settings.fullScreen;
      this.selectType = settings.selectType;
      this.selectionMode = settings.selectionMode;
      this.selected = Array.isArray(settings.selectedRecords) ? settings.selectedRecords : [];
      this.checkBoxesMode = 'always';

      if (typeof settings.filteringEnabled !== 'undefined') {
        this.filteringEnabled = !!settings.filteringEnabled;
      }

      this.include = settings.include?.length ? settings.include : [];
      this.exclude = settings.exclude?.length ? settings.exclude : [];
      void this.getSelectableNames(settings);
    } else {
      this._logger.debug('es wurden keine Settings als Parameter übergeben, verwende Werte aus DependencyInjection:');
    }

    this.dialogOpen = true;
  }

  calculateFilterExpression(selector: unknown): unknown {
    return [
      ['data.name', 'contains', selector],
      'or',
      ['data.profile.forename', 'contains', selector],
      'or',
      ['data.profile.surname', 'contains', selector]
    ];
  }

  async getSelectableNames(settings: INameSelectorDialogSettings): Promise<void> {
    const includes = settings?.include?.map(i => ({ type: i.type, targetId: i.targetId } as IPermissionTarget)) || [];
    const excludes = settings?.exclude?.map(i => ({ type: i.type, targetId: i.targetId } as IPermissionTarget)) || [];
    let selection: TSelectableNames = 'users';
    switch (settings.selectType) {
      case 'Beide':
        selection = 'usersAndRoles';
        break;
      case 'Rollen':
        selection = 'roles';
        break;

      default:
        break;
    }
    const data: ISelectableNames = { includes, excludes, selection };
    const selectables = await lastValueFrom(this._templateService.getSelectableNames(data));
    const dataResult: { type: string; id: string; data: IRole | IUser }[] = [];
    selectables.forEach(s => {
      const selectable = this.usersAndRoles.find(f => f.id === `${s.type}:${s.targetId}`);
      if (selectable) {
        if (selectable.type === 'user' && !(selectable.data as IUser).roles?.length) {
          return;
        }
        dataResult.push(selectable);
      }
    });
    dataResult.sort((a, b) => {
      const typeA = a.id.split(':')[0];
      const typeB = b.id.split(':')[0];

      if (typeA > typeB) {
        return -1;
      }

      if (typeA < typeB) {
        return 1;
      }

      if (typeA === 'user' && typeB === 'user') {
        const userA = (a.data as IUser).selectorName.toLowerCase();
        const userB = (b.data as IUser).selectorName.toLowerCase();
        return userA > userB ? 1 : userB > userA ? -1 : 0;
      }

      if (typeA === 'role' && typeB === 'role') {
        const nameA = a.data.name.toLowerCase();
        const nameB = b.data.name.toLowerCase();
        return nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
      }

      return 0;
    });

    const arrayStore = new ArrayStore({ key: 'id', data: dataResult });
    this.datasource = new DataSource({ store: arrayStore });
    this.selectedRecords = this.selected.map(entry => `${entry.type}:${entry.targetId}`);
    if (dataResult.length === 1 && !this.selectedRecords.length) {
      const item = dataResult[0].id;
      this.selectedRecords.push(item);
    }
  }

  dialogCancel = (): void => {
    void this.nameSelectorGrid?.instance?.deselectAll();
    void this.nameSelectorGrid?.instance?.clearFilter();
    void this.nameSelectorGrid?.instance?.clearGrouping();
    void this.nameSelectorGrid?.instance?.clearSorting();
    this.dialogOpen = false;
    this.notificationComponent.closeComponentNotifications();
    this.dialogHidden.emit(false);
  };

  dialogOk = (): void => {
    const nameSelectorGrid = this.nameSelectorGrid.instance;
    const result = nameSelectorGrid.getSelectedRowsData().map((entry: IUserRole) => entry.data);
    this.dialogResult.emit(result);
    this.dialogOpen = false;
  };

  onSelectionChanged(e: SelectionChangedEvent<INameSelectorDialogRecord, INameSelectorDialogRecord>): void {
    this.isOkButtonDisabled = e?.selectedRowKeys?.length < this.settings.minSelection && this.requiredSelection;
  }

  onCellPrepared(e: CellPreparedEvent<(IUser | IRole)[]>): void {
    if (e.rowType === 'filter' && e.column.caption === 'Name') {
      const cellElement = e.cellElement;
      this.editorElement = cellElement.querySelector('.dx-texteditor-input');
    }
  }

  onContentReady(): void {
    setTimeout(() => {
      this.editorElement.focus();
    }, 200);
  }

  getUsersAndRoles(): Observable<(IUser | IRole)[]> {
    this.initDataStore();
    // -- User & Rollendaten laden, falls benötigt
    const users$ = this._userService.getAllActiveUsers().pipe(
      map(
        users =>
          ({
            type: 'users',
            entries: [...users]
          } as { type: 'users'; entries: IUser[] })
      ),
      tap(list => {
        list.entries.sort((a, b) =>
          a.selectorName.toLowerCase() > b.selectorName.toLowerCase()
            ? 1
            : b.selectorName.toLowerCase() > a.selectorName.toLowerCase()
            ? -1
            : 0
        );
      }),
      takeUntil(this.#destroyable$)
    );

    const roles$ = this._roleService.getAllVisibleRoles().pipe(
      map(roles => roles.filter(r => r.isArchived === false)),
      map(
        roles =>
          ({
            type: 'roles',
            entries: [...roles]
          } as { type: 'roles'; entries: IRole[] })
      ),
      tap(list => {
        list.entries.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
        );
      }),
      takeUntil(this.#destroyable$)
    );

    roles$.subscribe(item => {
      const toConcat = item.entries.map((d: IRole) => ({ type: 'role', id: d.compositeId, data: d }));
      this.usersAndRoles = this.usersAndRoles.concat(toConcat);
    });

    users$.subscribe(item => {
      const toConcat = item.entries.map((d: IUser) => ({ type: 'user', id: d.compositeId, data: d }));
      this.usersAndRoles = this.usersAndRoles.concat(toConcat);
    });

    return EMPTY;
  }

  onSelect(e: RowClickEvent<INameSelectorDialogRecord, INameSelectorDialogRecord>): void {
    if (this.selectionMode === 'single') {
      return;
    }
    const keys = e.component.getSelectedRowKeys();
    const index = keys.indexOf(e.key);
    if (index > -1) {
      keys.splice(index, 1);
    } else {
      keys.push(e.key);
    }
    void e.component.selectRows(keys, false);
  }
}
